<template>
	<div>
	<ContentHeader title="Administrasi" />
     <div class="content-header">
		  <div class="container-fluid">

            <div class="card">
              <div class="card-header">
                <h3 class="card-title"><router-link to="/data-administrasi"><div style="#007bff">Data Administrasi</div></router-link></h3>

                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body" style="display: block;">
                <table class="table table-bordered table-hover">
        				  <thead>
        				    <tr>
        				      <th scope="col">KODE</th>
        				      <th scope="col">POLI</th>
        				      <th scope="col">NAMA ADMINISTRASI</th>
        				      <th scope="col">HARGA</th>
        				    </tr>
        				  </thead>
        				  <tbody>
        				    <tr v-for="row in administrasi" :key="row.id">
        				      <td scope="row">{{ row.kode_administrasi }}</td>
                      <td>{{ row.nama }}</td>
                      <td>{{ row.poli.nama }}</td>
                      <td>Rp. {{ number_format(row.harga, 0, ',', '.') }}</td>
        				    </tr>
        				  </tbody>
        				</table>
                <p class="text-center mt-2">{{cekDataAdministrasi}}</p>
              </div>
              <!-- /.card-body -->
              <div class="card-footer" style="display: block;">
                
              </div>
              <!-- /.card-footer-->
            </div>

            <div class="card">
              <div class="card-header">
                <h3 class="card-title"><router-link to="/data-jasa-pemeriksaan"><div style="#007bff">Data Jasa Pemeriksaan</div></router-link></h3>

                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                  <button type="button" class="btn btn-tool" data-card-widget="remove" title="Remove">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div class="card-body" style="display: block;">
                <table class="table table-bordered table-hover">
        				  <thead>
        				    <tr>
        				      <th scope="col">KODE</th>
        				      <th scope="col">POLI</th>
        				      <th scope="col">NAMA JASA PEMERIKSAAN</th>
        				      <th scope="col">HARGA</th>
        				    </tr>
        				  </thead>
        				  <tbody>
        				    <tr v-for="row in jasa" :key="row.id">
        				      <td scope="row">{{ row.kode_administrasi }}</td>
        				      <td>{{ row.nama }}</td>
        				      <td>{{ row.poli.nama }}</td>
        				      <td>Rp. {{ number_format(row.harga, 0, ',', '.') }}</td>
        				    </tr>
        				  </tbody>
        				</table>
                <p class="text-center mt-2">{{cekDataJasa}}</p>
              </div>
              <!-- /.card-body -->
              <div class="card-footer" style="display: block;">
                
              </div>
              <!-- /.card-footer-->
            </div>


            <div class="card">
              <div class="card-header">
                <h3 class="card-title"><router-link to="/uang-duduk"><div style="#007bff">Uang Duduk</div></router-link></h3>

                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                  <button type="button" class="btn btn-tool" data-card-widget="remove" title="Remove">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div class="card-body" style="display: block;">
                
              </div>
              <!-- /.card-body -->
              <div class="card-footer" style="display: block;">
                
              </div>
              <!-- /.card-footer-->
            </div>


		</div>
	</div>
    </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'

import axios from 'axios'
import store from '@/store'
import { ref, onMounted, computed } from 'vue' 
import number_format from '@/helper.js'

export default{
	components: {
		ContentHeader: ContentHeader
	},
	setup(){
        const user = computed(() => store.getters['auth/user'])

        const { administrasi, cekDataAdministrasi, getAdministrasi } = useGetAdministrasi(user)
        const { jasa, cekDataJasa, getJasa } = useGetJasa(user)

        // Refresh data 
        setInterval(() => {
          getAdministrasi()
          getJasa() 
        }, 240000)
        // End Refresh Data

        onMounted(() => {
            getAdministrasi()
            getJasa()
        })

        return {
            administrasi, jasa, number_format, cekDataAdministrasi, cekDataJasa
        }
    }
}



function useGetAdministrasi (user) {
  const administrasi = ref([])
  const cekDataAdministrasi = ref()
  
  const getAdministrasi = async () => {

    let response = await axios.get('api/administrasi/getWhereAdministrasi/' + user.value.cabang_id)
    if (response.data == 'kosong') {
      cekDataAdministrasi.value = 'Data Masih Kosong'
      administrasi.value = null
    } else {
      cekDataAdministrasi.value = ''
      administrasi.value = response.data
    }
  }

  return {
    administrasi,
    cekDataAdministrasi,
    getAdministrasi
  }
}



function useGetJasa (user) {  
  const jasa = ref([])
  const cekDataJasa = ref()

  const getJasa = async () => {
    let response = await axios.get('api/administrasi/getWhereJasa/' + user.value.cabang_id)
    if (response.data == 'kosong') {
      cekDataJasa.value = 'Data Masih Kosong'
      jasa.value = null
    } else {
      cekDataJasa.value = ''
      jasa.value = response.data
    }
  }

  return {
    jasa,
    cekDataJasa,
    getJasa
  }
}
</script>

<style>
	
</style>